import React from "react"
import StripeCheckout from "react-stripe-checkout"
import Image from "../../static/logo.png"
import { api } from "../store"

const STRIPE_PUBLISHABLE = "pk_live_8cNCITWCwknXWjlq9UDVo94J" //"pk_test_IXi1fbpf8L3DyIo05E9Julnr" //"pk_live_8cNCITWCwknXWjlq9UDVo94J"
const PAYMENT_SERVER_URL = `/.netlify/functions/makePayment`
const fromEuroToCent = amount => Math.round(amount * 100)

async function postData(url, data) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  return response.json() // parses JSON response into native JavaScript objects
}

async function postData2(url, data) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  return response.json() // parses JSON response into native JavaScript objects
}

//add token

const onToken = (amount, description, data, orders) => token => {
  const _data = {
    description,
    token,
    currency: "EUR",
    amount: fromEuroToCent(amount),
    idempotencyKey: data.idempotencyKey,
    data,
    orders,
  }

  fetch(PAYMENT_SERVER_URL, {
    method: "POST",
    body: JSON.stringify(_data),
  })
    .then(response => response.json())
    .then(data => {
      console.log("DATA", data)
      if (data.status === "succeeded") {
        api.setState({ payment: true })
        api.setState({ cart: [] })
        const url = `/.netlify/functions/sendEmailClient`
        postData(url, _data).then(data => {
          console.log("succes ", data)
        })
        const url2 = `/.netlify/functions/sendEmailEditorial`
        postData2(url2, _data).then(data => {
          console.log("succes ", data)
        })
      } else if (data.status === "missing-information") {
        api.setState({ paymentError: true })
      } else if (data.status === "failed") {
        api.setState({ paymentError: true })
      }
    })
    .catch(error => {
      console.error("Error:", error)
      api.setState({ paymentError: true })
    })
}

const Checkout = ({ name, description, amount, data, orders }) => (
  <>
    <StripeCheckout
      name={name}
      description={description}
      amount={fromEuroToCent(amount)}
      token={onToken(amount, description, data, orders)}
      currency={"EUR"}
      email={data.email}
      image={Image}
      locale={`es`}
      allowRememberMe={false}
      label={`Confirmar i pagar ${amount} €`}
      stripeKey={STRIPE_PUBLISHABLE}
    />
  </>
)

export default Checkout
