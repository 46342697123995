import React from "react"
import Layout from "../components/Layout"
import { useStore, api } from "../store"
import FormComanda from "../components/form-comanda"
import Image from "gatsby-image"
import { Link } from "gatsby"
import slugify from "slugify"
import { useSpring, a } from "react-spring"
import Vines from "../components/vines"

const Botiga = () => {
  const { duplicateBookFromCart, removeBookFromCart } = useStore(
    state => state.actions
  )
  const { cart, payment, paymentError } = useStore(state => state)

  const styleProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })
  const ThumbnailBook = book => {
    return (
      <a.div style={styleProps}>
        <div class="flex mb-2 border-myblack border-b">
          <div class="w-auto h-auto">
            <Link
              to={`/cataleg/${slugify(book.title, { lower: true })}`}
              className="cursor-pointer"
            >
              <Image fluid={book.imatge.small.fluid} className="h-24 w-16" />
            </Link>
          </div>
          <div class="w-full  h-auto">
            <p className="ml-4">
              <span className="text-xs font-title italic font-bold truncate overflow-hidden">
                {book.title}
              </span>
            </p>

            <p className="ml-4 -my-2">
              <span className="text-xs font-title">{book.autors[0]}</span>
            </p>

            <p className="ml-4 mt-1">
              <span className="text-sm font-title text-malesherbes">
                {book.preu.toFixed(2)} €
              </span>
            </p>
            <p className="ml-4 mt-1">
              <span
                onClick={() => removeBookFromCart(book)}
                className="text-sm font-title  hover:underline cursor-pointer"
              >
                Eliminar
              </span>

              <span
                onClick={() =>
                  duplicateBookFromCart(
                    Object.assign({}, book, { id: Date.now() })
                  )
                }
                className="text-sm ml-2 font-title hover:underline cursor-pointer "
              >
                Replicar
              </span>
            </p>
          </div>
        </div>
      </a.div>
    )
  }

  return (
    <Layout place="botiga">
      <div className="flex flex-wrap mt-6">
        <div className="w-full">
          <p className="font-title uppercase text-gray-900 text-xl border-b border-black">
            Botiga
          </p>
        </div>
        {!payment && cart.length === 0 && (
          <div className="flex flex-wrap mt-6">
            <div className="w-full h-screen bg-white">
              <div class="flex items-center justify-center">
                <div class="w-1/2 text-xl font-title text-center mt-16">
                  Encara no has afegit cap llibre a la cistella...
                  <Vines />
                </div>
              </div>
            </div>
          </div>
        )}
        {payment && (
          <div className="w-full h-screen bg-white">
            <div class="flex items-center justify-center ">
              <div class="w-1/2 text-xl font-title text-center mt-16">
                Moltes gràcies per la teva compra!
                <button
                  onClick={() => {
                    api.setState({ payment: false })
                  }}
                  className="bg-malesherbes mb-2 w-full md:w-1/2 font-title hover:bg-pagination uppercase text-black text-center py-2 rounded-sm px-4 mt-6"
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        )}
        {paymentError && (
          <div className="w-full h-screen bg-white">
            <div class="flex items-center justify-center">
              <div class="w-1/2 text-xl font-title text-center mt-16">
                ERROR, no s'ha pogut processar el pagament
                <button
                  onClick={() => {
                    api.setState({ paymentError: false })
                  }}
                  className="bg-malesherbes mb-2 w-full md:w-1/2 font-title hover:bg-pagination uppercase text-black text-center py-2 rounded-sm px-4 mt-6"
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {!payment && cart.length > 0 && (
        <div class="flex flex-wrap mb-4 -mx-2 -my-4 mt-12">
          <div class="w-full px-4 py-4  sm:w-full md:w-1/2 ">
            <p className="">
              {cart.map(book => (
                <ThumbnailBook {...book} />
              ))}
            </p>
            <p className="font-title font-bold mt-4">
              Preu Final:{" "}
              <span className="font-normal text-myblack">
                {cart
                  .map(b => b.preu)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}{" "}
                €
              </span>
            </p>
            <p className="font-title font-bold">
              Quantitat de llibres:{" "}
              <span className="font-normal text-myblack">{cart.length}</span>
            </p>
            <br/>
            <p className="font-title font-normal italic">
              Despeses d'enviament gratuïtes!
            </p>
            <p className="font-title font-bold">
              T'enviem la comanda a través de correus. La rebràs en un termini d'entre 3 i 7 dies laborables. 
            </p>
          </div>

          <div class="w-full md:w-1/2 px-4 py-3 text-lg mb-16 text-center">
            <FormComanda />
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Botiga
