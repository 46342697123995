import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useStore } from "../store"
import Checkout from "../components/Checkout"
import { FaCcVisa, FaStripe } from "react-icons/fa"

export default function FormComanda() {
  const { register, handleSubmit, errors } = useForm()
  const { cart, saveFormData, formData } = useStore(state => state)
  const [open, setOpen] = useState(false)
  const onSubmit = data => {
    saveFormData(data)
    setOpen(true)
  }

  return (
    <>
      {!open && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="font-medium">
            Emplena les següents dades per procedir a la compra:
          </p>
          <br />
          <div class="md:flex md:items-center">
            <div class="md:w-full">
              <input
                type="text"
                placeholder="Nom"
                name="name"
                defaultValue={formData && formData.name}
                className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
                ref={register({ required: true, min: 3 })}
              />
              {errors.name && (
                <p class="text-red-500 normal-case text-md italic">
                  Camp obligatori
                </p>
              )}
            </div>
          </div>
          <div class="md:flex md:items-center2">
            <div class="md:w-full">
              <input
                type="text"
                placeholder="Cognoms"
                name="surname"
                defaultValue={formData && formData.surname}
                className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
                ref={register({ required: true, min: 3 })}
              />
              {errors.surname && (
                <p class="text-red-500 normal-case text-md italic">
                  Camp obligatori
                </p>
              )}
            </div>
          </div>
          <div class="md:flex md:items-center">
            <div class="md:w-full">
              <input
                type="email"
                placeholder="Email"
                name="email"
                defaultValue={formData && formData.email}
                className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
                ref={register({ required: true, pattern: /^\S+@.\S+$/i })}
              />
              {errors.email && (
                <p class="text-red-500 normal-case text-md italic">
                  Camp obligatori
                </p>
              )}
            </div>
          </div>
          <div class="md:flex md:items-center">
            <div class="md:w-full">
              <input
                type="text"
                placeholder="Adreça"
                name="adress"
                defaultValue={formData && formData.adress}
                className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
                ref={register({ required: true, min: 3 })}
              />
              {errors.adress && (
                <p class="text-red-500 normal-case text-md italic">
                  Camp obligatori
                </p>
              )}
            </div>
          </div>
          <div class="md:flex md:items-center">
            <div class="md:w-full">
              <input
                type="text"
                placeholder="Ciutat"
                name="city"
                defaultValue={formData && formData.city}
                className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
                ref={register({ required: true, min: 3 })}
              />
              {errors.city && (
                <p class="text-red-500 normal-case text-md italic">
                  Camp obligatori
                </p>
              )}
            </div>
          </div>

          <div class="md:flex md:items-center">
            <div class="md:w-full">
              <input
                type="text"
                placeholder="Codi Postal"
                name="cp"
                defaultValue={formData && formData.cp}
                className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
                ref={register({ required: true, min: 3 })}
              />
              {errors.city && (
                <p class="text-red-500 normal-case text-md italic">
                  Camp obligatori
                </p>
              )}
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-full">
              <input
                type="text"
                placeholder="Provincia"
                name="province"
                defaultValue={formData && formData.province}
                className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
                ref={register({ required: true, min: 3 })}
              />
              {errors.province && (
                <p class="text-red-500 normal-case text-md italic">
                  Camp obligatori
                </p>
              )}
            </div>
          </div>
          <button
            onClick={() => onSubmit}
            className="bg-malesherbes mb-2 w-1/2 font-title hover:bg-pagination uppercase text-black text-center py-2 rounded-sm px-4 mt-6"
          >
            Continuar
          </button>
        </form>
      )}

      <br />

      {open && (
        <>
          <p className="font-semibold">Les teves dades:</p>
          <p>
            Nom: {formData.name} {formData.surname}
          </p>
          <p>
            Adreça: {formData.adress}
            <br /> {formData.cp}
            <br />
            {formData.city} ({formData.province})
          </p>
          <p>Email: {formData.email}</p>
          <p
            class="italic hover:underline cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            Modificar dades de l'enviament
          </p>
          <br />
          <p className="font-semibold">La teva comanda:</p>
          {cart.map(c => {
            return (
              <p>
                - {c.title} <span className="italic">{c.autors[0]}</span>
              </p>
            )
          })}
          <br />
          <div className="flex flex-wrap mt-2 justify-center mb-1">
            <FaCcVisa className="w-8 h-8 mr-2" color={"1a1f71"} />
            <FaStripe className="w-8 h-8" color={"1a1f71"} />
          </div>
          <Checkout
            name={`Editorial Males Herbes`}
            description={`Comanda de llibres`}
            amount={cart
              .map(b => b.preu)
              .reduce((a, b) => a + b, 0)
              .toFixed(2)}
            books={cart}
            data={{
              email: formData.email,
              idempotencyKey: Math.random()
                .toString(36)
                .slice(2),
              name: formData.name,
              surname: formData.surname,
              address: formData.adress,
              city: formData.city,
              province: formData.province,
              cp: formData.cp,
            }}
            orders={cart}
          />
        </>
      )}
    </>
  )
}
